const animates = document.querySelectorAll(".animate");
const observer = new IntersectionObserver(
    entries => {
        entries.forEach(entry => {
            entry.target.classList.toggle("in-view", entry.isIntersecting);
            if(entry.isIntersecting) observer.unobserve(entry.target);            
        });
    },
    {
        threshold: 0.1
    }
);
animates.forEach(animate => {
    observer.observe(animate);
});